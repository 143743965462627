import React, { useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faReact,
  faHtml5,
  faCss3,
  faJs,
  faVuejs,
  faNode,
} from "@fortawesome/free-brands-svg-icons";
import CustomHook from "./CustomHook";

function Skills() {
  const divs = useRef([]);
  const scrollTab = useRef();
  CustomHook(scrollTab, divs);
  const [listSkills] = useState([
    {
      name: "HTML",
      des: "HTML is a core skill I use to structure web content. As a web developer, I understand basic syntax, semantic HTML, and how to create clean, accessible, and SEO-friendly markup. I'm confident in building web pages and continuously improving my HTML skills.",
      icon: faHtml5,
    },
    {
      name: "CSS",
      des: "CSS is essential for styling and designing web pages. As a web developer, I use CSS to control layouts, colors, fonts, animation and overall page aesthetics. I have a solid understanding of responsive design, flexbox, and grid systems, allowing me to create visually appealing and user-friendly interfaces. I'm continually enhancing my CSS skills to deliver better design solutions.",
      icon: faCss3,
    },
    {
      name: "Javascript",
      des: "JavaScript is a key skill I use to add interactivity and dynamic behavior to web pages. As a web developer, I understand core concepts like variables, functions, loops, and events. I also work with DOM manipulation to create responsive and engaging user experiences. I’m continuously improving my JavaScript knowledge to build more efficient and interactive web applications.",
      icon: faJs,
    },
    {
      name: "ReactJs",
      des: "ReactJS is my primary tool for building dynamic and interactive user interfaces. With 3 years of experience, I have a deep understanding of React concepts like components, state, props, and hooks. I use React to create efficient, reusable components and manage complex application logic. My expertise includes working with React Router, managing global state with libraries like Redux, and optimizing performance. I am confident in building scalable, maintainable applications and continuously improving my ReactJS skills.",
      icon: faReact,
    },
    {
      name: "VueJs",
      des: "Vue.js is a valuable skill I use for building user interfaces and single-page applications. As a web developer, I have a solid understanding of Vue’s core concepts, including components, directives, and the Vue instance. I enjoy creating reactive and dynamic web applications using Vue’s declarative syntax. I’m continually learning about Vue Router and Vuex to enhance my skills and build more complex applications.",
      icon: faVuejs,
    },
    {
      name: "NodeJs",
      des: "Node.js is a key skill I use for server-side development. As a junior developer, I understand the fundamentals of building APIs and managing server-side logic using JavaScript. I’m familiar with core modules, Express.js for routing, and basic database interactions. I am eager to expand my knowledge of asynchronous programming and explore more advanced Node.js features to enhance my back-end development skills.",
      icon: faNode,
    },
  ]);
  return (
    <section className="skills" ref={scrollTab}>
      <div className="title" ref={(el) => el && divs.current.push(el)}>
        This is my Skills
      </div>
      <div className="des" ref={(el) => el && divs.current.push(el)}>
        {/* 20 */}I have a range of skills in front-end development,
        particularly with HTML, CSS, and JavaScript. I'm skilled in using modern
        frameworks like React and understand responsive design well. I know
        version control systems like Git and use tools like ESLint and Prettier
        for clean code. My teamwork abilities, commitment to staying current
        with technologies, and focus on improving my skills help me create
        effective and attractive web applications.
      </div>
      <div className="list">
        {listSkills.map((value, key) => (
          <div
            className={"item"}
            key={key}
            ref={(el) => el && divs.current.push(el)}
          >
            <FontAwesomeIcon icon={value.icon} />
            <h3>{value.name}</h3>
            <div className="des">{value.des}</div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Skills;
