import React, { useState, useRef } from "react";
import CustomHook from "./CustomHook";

function Contacts() {
  const [listContacts] = useState([
    {
      title: "Phone Number",
      value: "+84943116207",
    },
    {
      title: "Email",
      value: "nguyentran.dev@gmail.com",
    },
    {
      title: "Facebook",
      value: "https://www.facebook.com/nguyenthtran.dev",
    },
  ]);
  const divs = useRef([]);
  const scrollTab = useRef();
  CustomHook(scrollTab, divs);

  return (
    <section className="contacts" ref={scrollTab}>
      <div className="title" ref={(el) => el && divs.current.push(el)}>
        This is my Contacts
      </div>
      <div className="des" ref={(el) => el && divs.current.push(el)}>
        I am always open to connecting and discussing opportunities. You can
        reach me via email or connect with me on Facebook or Phone number. I
        welcome inquiries related to collaboration, networking, or any questions
        about my work. Feel free to reach out, and I will respond as soon as
        possible!
      </div>
      <div className="contacts-info">
        <div className="list" ref={(el) => el && divs.current.push(el)}>
          {listContacts.map((value, key) => (
            <div className="item" key={key}>
              <h3>{value.title}</h3>
              <div>{value.value}</div>
            </div>
          ))}
        </div>
        <div className="qr-contact" ref={(el) => el && divs.current.push(el)}>
          <img src="https://res.cloudinary.com/dvntlsvgf/image/upload/v1732243274/DevFlow/Logo/qr_1_jz6bzi.png" />
        </div>
      </div>
    </section>
  );
}
export default Contacts;
