import React, { useRef, useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHtml5 } from "@fortawesome/free-brands-svg-icons";
import CustomHook from "./CustomHook";

// Custom hook để kiểm tra viewport
const useInViewEffect = (items) => {
  useEffect(() => {
    const isElementInViewport = (el) => {
      const rect = el.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      );
    };

    const callbackFunc = () => {
      items.current.forEach((item) => {
        if (isElementInViewport(item)) {
          item.classList.add("in-view");
        } else {
          item.classList.remove("in-view");
        }
      });
    };

    window.addEventListener("load", callbackFunc);
    window.addEventListener("resize", callbackFunc);
    window.addEventListener("scroll", callbackFunc);

    return () => {
      window.removeEventListener("load", callbackFunc);
      window.removeEventListener("resize", callbackFunc);
      window.removeEventListener("scroll", callbackFunc);
    };
  }, [items]);
};

function Education() {
  const divs = useRef([]);
  const scrollTab = useRef();
  CustomHook(scrollTab, divs);

  const [listSkills] = useState([
    {
      title: "FPT Greenwich University",
      time: "07/2018 - 07/2022",
      content: "Major: Information technology",
      subContent: "GPA: Second Class Honours",
    },
    {
      title: "Professional Programmer Training Center iViettech",
      time: "03/2021 - 10/2021",
      content: "Major: Front-End Developer",
      subContent: "GPA: 7.6",
    },
    {
      title: "GMO-Z.com Runsystem Company",
      time: "11/2021 - 05/2022",
      content: "Department: Front-End Development",
      subContent: "Position: Fresher",
    },
    {
      title: "GMO-Z.com Runsystem Company",
      time: "05/2022 - Now",
      content: "Department: Front-End Development",
      subContent: "Position: Junior",
    },
  ]);

  // Timeline items
  const timelineItems = useRef([]);
  useInViewEffect(timelineItems);

  return (
    <section className="education" ref={scrollTab}>
      <div className="title" ref={(el) => el && divs.current.push(el)}>
        Learning & Growth
      </div>
      <section className="timeline" ref={(el) => el && divs.current.push(el)}>
        <ul>
          {listSkills.map((item, index) => (
            <li key={index} ref={(el) => el && timelineItems.current.push(el)}>
              <div>
                <time>{item.title}</time>
                <p>{item.time}</p>
                <p>{item.content}</p>
                <p>{item.subContent}</p>
              </div>
            </li>
          ))}
        </ul>
      </section>
    </section>
  );
}

export default Education;
